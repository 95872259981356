import React, { useEffect, useState } from "react";
import firebase, { db } from "../firebase";

const UserContext = React.createContext({});

const AuthManager = props => {
  const [state, setState] = useState("LOADING");
  const [user, setUser] = useState(null);

  // set username on oAuth login
  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then(result => {
        const { user } = result;
        if (user) {
          db.collection("users")
            .doc(user.uid)
            .set(
              {
                username: user.displayName,
              },
              { merge: true }
            )
            .catch(function(error) {
              console.error("Error writing document: ", error);
            });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(firebaseUser => {
      setUser(firebaseUser);
      setState("IDLE");
    });
  }, []);

  const signIn = async username => {
    try {
      console.log(username);
      const result = await firebase.auth().signInAnonymously();
      const user = result.user;
      db.collection("users")
        .doc(user.uid)
        .set({ username }, { merge: true })
        .catch(function(error) {
          console.error("Error writing document: ", error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const singInFacebook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  };

  const signOut = () => {
    firebase.auth().signOut();
  };

  const manager = {
    user: user,
    userStatus: state,
    signIn,
    signOut,
    singInFacebook,
  };

  return (
    <UserContext.Provider value={manager}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
export { AuthManager };
