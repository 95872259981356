import app from 'firebase/app';
import 'firebase/firestore';
import "firebase/auth";

const config = {
  apiKey: "AIzaSyCim7OzO44FHB9xX6FsHc9TQnqorL2TT_o",
  authDomain: "whist-32d88.firebaseapp.com",
  databaseURL: "https://whist-32d88.firebaseio.com",
  projectId: "whist-32d88",
  storageBucket: "whist-32d88.appspot.com",
  messagingSenderId: "614767566718",
  appId: "1:614767566718:web:5ebb8c097bd8c3bd075d2f",
  measurementId: "G-4CJ3X8DC9N"
};

app.initializeApp(config);

export default app;

export const db = app.firestore();
